import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './screens/Login';
import Admin from './screens/Admin';
import User from './screens/User';
// import { Security, LoginCallback } from '@okta/okta-react';
// import { oktaConfig } from './config/oktaConfig.js';
// ====================================
function App() {
  return (
      <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/user" element={<User />} />
        
      </Routes>
    </Router>
  );
}

export default App;
