import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col, Card, message } from 'antd';
import { MinusCircleOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import { BACKEND_BASE_URL } from '../../config/api';
// ==================================================================================
// AddPermissionForm component
// READ ME:
//
// This component is used to manage permissions for a specific step. It contains a form with input fields for email addresses, which are used to grant or revoke permissions for the step. The form submission logic is handled by the onFinish function, which sends a POST request to the backend API to update the permissions. The component also includes a button to toggle edit mode, which allows users to add or remove permissions.
// ==================================================================================

const AddPermissionForm = ({ stepNumber, stepName }) => {
  const [form] = Form.useForm();
  const [initialPermissions, setInitialPermissions] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const fetchPermissions = async () => {
    try {
      const response = await axios.get(`${BACKEND_BASE_URL}/admin/details` , {
        withCredentials: true,
        params: {
          stepNumber,
        },
      });
      const permissionsData = response.data.accessList; 
      setInitialPermissions(permissionsData);
      form.setFieldsValue({ permissions: permissionsData.map(email => ({ email })) });
    } catch (error) {
      console.error('Failed to fetch permissions:', error);
      message.error('Failed to load permissions');
    }
  };

  useEffect(() => {
    fetchPermissions();
  }, [stepNumber, form]);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const onFinish = async (values) => {
    const finalEmails = values.permissions.map(permission => permission.email);
    const addedEmails = finalEmails.filter(email => !initialPermissions.includes(email));
    const removedEmails = initialPermissions.filter(email => !finalEmails.includes(email));

    try {
      const addPromises = addedEmails.map(email =>
        axios.post(`${BACKEND_BASE_URL}/admin/add-permission`,  {
          emailAddress: email,
          stepNumber,
        }, { withCredentials: true})
      );

      const removePromises = removedEmails.map(email =>
        axios.post(`${BACKEND_BASE_URL}/admin/remove-permission`, {
          emailAddress: email,
          stepNumber,
        }, { withCredentials: true})
      );

      await Promise.all([...addPromises, ...removePromises]);
      message.success('Permissions updated successfully');
      fetchPermissions(); // Refresh the list of permissions
      toggleEditMode(); // Exit edit mode
    } catch (error) {
      console.error('Failed to update permissions:', error);
      message.error('Failed to update permissions');
    }
  };

  return (
    <Card 
      title={` ${stepName} `} 
      style={{ marginBottom: 16 }}
      extra={<Button onClick={toggleEditMode}>{editMode ? 'Cancel' : 'Edit'}</Button>}>
        {editMode ? 
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.List name="permissions" initialValue={initialPermissions.map(email => ({ email }))}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Row key={key} gutter={8} align="middle" wrap={false}>
                  <Col flex="auto">
                    <Form.Item
                      {...restField}
                      name={[name, 'email']}
                      fieldKey={[fieldKey, 'email']}
                      rules={[{ required: true, message: 'Missing email' }, { type: 'email', message: 'Invalid email' }]}
                    >
                      <Input placeholder="Email address" disabled={!editMode} />
                    </Form.Item>
                  </Col>
                  {editMode && (
                    <Col>
                      <MinusCircleOutlined  style={{marginBottom: 25}} onClick={() => remove(name)} />
                    </Col>
                  )}
                </Row>
              ))}
              {editMode && (
                <Row>
                  <Col span={24}>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{marginBottom: 10 }}>
                      Add Permission
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Form.List>
        {editMode && (
          <Row justify="end">
            <Col>
              <Button type="primary" htmlType="submit">
                Save Changes
              </Button>
            </Col>
          </Row>
        )}
      </Form> 
      : 
      <div>
        <ul>
          {initialPermissions.map(email => (
            <li key={email}>
             {email}
            </li>
          ))}
        </ul>
      </div> }
    </Card>
  );
};

export default AddPermissionForm;