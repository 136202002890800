import { useState } from 'react';
import { Button, Modal} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddStepForm from './AddStepForm';
import ViewAllStepsButton from './StepDetails';
// ==================================================================================
// AddStepButton component
// READ ME:
//
// This component is used to add a new step. It contains a button that opens a modal with the AddStepForm component.
// ==================================================================================

const AddStepButton = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleSubmitSuccess = () => {
  setIsModalVisible(false);
  };

  return (
    <>
    <ViewAllStepsButton />
      <Button 
        type="primary" 
        onClick={showModal}
        icon={<PlusOutlined />}
        style={{ float: 'right', margin: '10px' }}
      >
        New Order
      </Button>
    
      <Modal
        title="Add New Order"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <AddStepForm onCancel={handleCancel} onSubmitSuccess={handleSubmitSuccess} />
      </Modal>
    </>
  );
};

export default AddStepButton;
