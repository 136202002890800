import DataTable from '../component/user/DataTable';
import Logo from '../exo_logo.jpg';
import { Card, Button } from 'antd';
import { BACKEND_BASE_URL } from '../config/api';
// ==========================================================
const User= () => {
  const handleLogout = () => {
    window.location.href = `${BACKEND_BASE_URL}/auth/signout`;
  };

    return (
      <div style={{ margin: '5px', padding: '5px' }}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
       <div style={{ display: 'flex', marginBottom: '20px' }}>
        <img src={Logo} alt="Logo" style={{ marginRight: '10px', height: '50px' , marginTop: '15px'}} />
        <h1>EXO Build Tracking Platform</h1>
        </div>
      <Button type="primary" style={{ marginTop: '30px' }} onClick={handleLogout}>Logout</Button>
      </div>
       <Card title={<span style={{ fontSize: '20px', fontWeight: 'bold' }}>In Progress Build</span>} style={{ width: '100%', border: '3px solid #e8e8e8' }}>
            <DataTable />
        </Card>
        </div>
    );
  };
  
  export default User;