import DataTable from '../component/user/DataTable';
import SummaryTable from '../component/admin/SummaryTable';
import AddStepForm from '../component/admin/AddStep';
import PermissionCard from '../component/admin/PermissionCard';
import { Button, Card , Col, Row} from 'antd';
import Logo from '../exo_logo.jpg';
import { BACKEND_BASE_URL } from '../config/api';
import AddPermissionForm from '../component/admin/AddPermissionForm';
// ===========================================================================


const Admin = () => {
  const handleLogout = () => {
    window.location.href = `${BACKEND_BASE_URL}/auth/signout`;
  };

  return (
    <div style={{ margin: '5px', padding: '5px' }}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
       <div style={{ display: 'flex', marginBottom: '20px' }}>
        <img src={Logo} alt="Logo" style={{ marginRight: '10px', height: '50px' , marginTop: '15px'}} />
        <h1>EXO Build Tracking Platform</h1>
        </div>
      <Button type="primary" style={{ marginTop: '30px' }} onClick={handleLogout}>Logout</Button>
      </div>
        <Row gutter={[16, 16]}>
        <Col span={24}>
          <AddStepForm />
        </Col>
        <Col span={24}>
          <Card title={<span style={{ fontSize: '20px', fontWeight: 'bold' }}>Summary Table</span>} style={{ width: '100%', border: '3px solid #e8e8e8' }}>
            <SummaryTable />
          </Card>
        </Col>
        <Col span={24}>
          <Card title={<span style={{ fontSize: '20px', fontWeight: 'bold' }}>In Progress Build</span>} style={{ width: '100%', border: '3px solid #e8e8e8' }}>
            <DataTable />
          </Card>
        </Col>
        <Col span={24}>
          <PermissionCard />
        </Col>
      </Row>
  
    </div>
  )
};

export default Admin;
