import { useState, useEffect } from 'react';
import { Card, Table, message, Button } from 'antd';
import axios from 'axios';
import { io } from 'socket.io-client';
import { BACKEND_BASE_URL } from '../../config/api';
import { DownOutlined, UpOutlined} from '@ant-design/icons';
// ==================================================================================
// SummaryTable component
// READ ME:
// This component is used to display a summary table of build data. It fetches the data from the backend API and renders it in a table format. The component also includes a socket connection to receive real-time updates on the build data.
// ==================================================================================

const SummaryTable = () => {
  const [data, setData] = useState([]);
  const [socket, setSocket] = useState(null);
  const [updatesData, setUpdatesData] = useState([]);
  const [expandedBuilds, setExpandedBuilds] = useState(new Set());

// =========================================================
// Table Custom Styles
  const borderRightStyle = {
    borderRight: '1px solid #d9d9d9', 
    paddingRight: '8px',
    display: 'flex',
    width: '100%', 
    height: '100%',
    alignItems: 'center',
    textAlign: 'center',
  display: 'block'
  };
  const noWrapStyle = {
    whiteSpace: 'nowrap',
    overflow: 'visible',
    // textOverflow: 'ellipsis',
    minWidth: '100px',
  };


  useEffect(() => {
    const newSocket = io(`${BACKEND_BASE_URL}`);
    setSocket(newSocket);

    // =========================================================
    // Fetch the summary data from the backend API

    const fetchSummaryData = async () => {
      try {
        const response = await axios.get(`${BACKEND_BASE_URL}/admin/summary-table`, { withCredentials: true });
        setData(formatData(response.data));


      } catch (error) {
        console.error('Error fetching summary data:', error);
        message.error('Error fetching summary data');
      }
    };
// ===========================================================
// Fetch the updates data from the backend API
    const fetchStepsData = async () => {
      try {
        const response = await axios.get(`${BACKEND_BASE_URL}/updates`, { withCredentials: true }); 
        setUpdatesData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Failed to fetch updates on steps:', error);
        message.error('Failed to load updates on steps data');
      }
    };
    fetchSummaryData();
    fetchStepsData();


    // Setup socket listeners for real-time updates
    if (newSocket) {
      newSocket.on('stepDataChange', () => {
        fetchSummaryData();
        fetchStepsData();
      });
    }

    return () => {
      newSocket.close();
    };
  }, []);
 
// ==============================================================
  // Format data to fit the table structure
  const formatData = (data) => {
    return data.map((item, index) => ({
      key: index + 1,
      buildName: item.buildName,
      ...item.steps.reduce((acc, step) => {
        // Add N/A if the quantity is null and is not added to the DataBase
        acc[`step${step.stepNumber}Quantity`] = step.quantity || 'N/A';
        acc[`step${step.stepNumber}Pass`] = (step.quantityPass !== null ? step.quantityPass : 'N/A');
        acc[`step${step.stepNumber}Reject`] = (step.quantityReject !== null ? step.quantityReject : 'N/A');
        return acc;
      }, {}),
    }));
  };
  // ===============================================================
  // Toggle the expanded state of a build
const toggleExpand = (buildName) => {
  setExpandedBuilds(prev => {
    const newExpanded = new Set(prev);
    if (newExpanded.has(buildName)) {
      newExpanded.delete(buildName);
    } else {
      newExpanded.add(buildName);
    }
    return newExpanded;
  });
};
// ===============================================================
  // Define columns for the Ant Design table
  const columns = [
    // {
    //   title: 'Number',
    //   dataIndex: 'key',
    //   key: 'key',
    // },
    {
      title: 'Build Name',
      dataIndex: 'buildName',
      key: 'buildName',
      fixed: 'left',
      width: 200,
      sorter: (a, b) => a.buildName.localeCompare(b.buildName),
      defaultSortOrder: 'descend',
      render: text => <div style={noWrapStyle}>{text}</div>
    },
    {
      title: 'ABR Order',
      dataIndex: 'step1Quantity',
      key: 'step1Quantity',
      align: 'center',
    },
    // {
    //   title: 'Chip',
    //   dataIndex: 'step2Quantity',
    //   key: 'step2Quantity',
    //   align: 'center',
    //   render: text => <div style={borderRightStyle}>{text}</div>,
    // },
  ];
// ================================================================
  // Add columns for steps 2 through 6b with children
  for (let i = 2; i <= 8; i++) {
    let title = ''
    switch (i){
      case 2:
        title = 'Tile Assembly'
        break;
      case 3:
        title = 'Lensing'
        break;
        case 4:
          title = 'Cleaning/Singulation'
          break;
        case 5:
          title = 'Inspection'
          break;
        case 6:
          title = 'Test'
          break;
          case 7:
          title = 'Final Outgoing Inspection'
          break;
        case 8:
          title = 'Ship to Probe'
          break;
    }

    columns.push({
      title: `${title}`,
      children: [
        {
          title: 'Pass',
          dataIndex: `step${i}Pass`,
          key: `step${i}Pass`,
          align: 'center',
          
        },
        {
          title: 'Reject',
          dataIndex: `step${i}Reject`,
          key: `step${i}Reject`,
          render: text => <div style={borderRightStyle}>{text}</div>,
          align: 'center',
        },
      ],
      align: 'center',
    });
  }

  return (
    <>
  <Table 
      dataSource={data} 
      columns={columns} 
      virtual scroll={{ x: 1500, y: 500 }}
      pagination={{ pageSize: 10 }}
  />
<h3 style={{ fontSize: '20px', fontWeight: 'bold' }}>Updates</h3>
{updatesData.map((build) => (
      <Card key={build.buildName} 
      style={{ marginTop: '20px', width: '100%' }}
        title={<div className="card-header" style={{  display: 'flex', justifyContent: 'space-between'}}>
          <h4 style={{ display: 'flex', alignItems: 'center'}}>{build.buildName}</h4>
          <Button onClick={() => toggleExpand(build.buildName)} style={{ cursor: 'pointer', marginTop: '10px' }}>
          {expandedBuilds.has(build.buildName) ? <UpOutlined /> : <DownOutlined />}
        </Button>
        </div>}>
        {expandedBuilds.has(build.buildName) && (
            <>
        {build.steps.map((step) => (
           <div key={step.stepNumber}>
           <h3>{step.stepName}</h3>
           {step.updates.map((update, index) => (
             <p key={index}>{update}</p>
           ))}
         </div>
       ))}
        </>
        )}
     </Card>
   ))}
  </>
  
  );
};

export default SummaryTable;

