import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Row, Col, Input, DatePicker, message, Modal } from 'antd';
import moment from 'moment';
import { DownOutlined, UpOutlined ,PartitionOutlined, ExceptionOutlined} from '@ant-design/icons';
import axios from 'axios';
import io from 'socket.io-client';
import { BACKEND_BASE_URL } from '../../config/api';
import { BASE_URL } from '../../config/api';

// ==================================================================================
// DataTable component
// READ ME:
//
// This component is used to display a table of build steps. It fetches the data from the backend API and renders it in a table format. The component includes a form for each step, which allows users to edit the step details. The form submission logic is handled by the handleSave function, which sends a POST request to the backend API to update the step details. The component also includes a button to mark a step as done, which sends a POST request to the backend API to update the step status.
// ==================================================================================

const DataTable = () => {
  const [stepsData, setStepsData] = useState([]);
  const [freshStepData, setFreshStepData] = useState([]);
  const [editStepId, setEditStepId] = useState(null); // Track which step is being edited
  const [expandedStepIds, setExpandedStepIds] = useState(new Set()); //Expand the step
  const [expandedBuilds, setExpandedBuilds] = useState(new Set()); // Expand the build
const [socket, setSocket] = useState(null);
// const [form] = Form.useForm();
// ------------------ Partial/Split show Modal
const [showModal, setShowModal] = useState(false);
const [currentStepValues, setCurrentStepValues] = useState(null);
const [currentStepId, setCurrentStepId] = useState(null);
const [partial, setPartial] = useState(false);
const [done, setDone] = useState(false);
// ------------------

// FETCH STEP DATA ==========================================
const fetchStepsData = async () => {
  try {
    const response = await axios.get(`${BACKEND_BASE_URL}/`, { withCredentials: true });
    setStepsData(response.data);
    // console.log('This is the response:', response.data);

  } catch (error) {
    console.error('Failed to fetch steps data:', error);
    message.error('Failed to load steps data');
    window.location.href = `${BASE_URL}`;
  }
};

// TOGGLE EXPAND STEPS ============================================
const toggleExpand = (stepId) => {
  setExpandedStepIds(prev => {
    const newExpanded = new Set(prev);
    if (newExpanded.has(stepId)) {
      newExpanded.delete(stepId);
    } else {
      newExpanded.add(stepId);
    }
    return newExpanded;
  });
};
// TOGGLE EXPAND BUILDS ============================================
const toggleExpandB = (buildName) => {
  setExpandedBuilds(prev => {
    const newExpanded = new Set(prev);
    if (newExpanded.has(buildName)) {
      newExpanded.delete(buildName);
    } else {
      newExpanded.add(buildName);
    }
    return newExpanded;
  });
};
// HANDLE EDIT CLICK ========================================
  const handleEditClick = (stepId) => {
    setEditStepId(prev => prev === stepId ? null : stepId); // Toggle edit mode for this step
  };

  // Onchange Values ========================================
  const handleInputChange = (e) => {
    setCurrentStepValues(e.target.value);
  };

  // HANDLE SAVE ==============================================
  const handleSave = async (stepId, values) => {
    try {
      await axios.post(`${BACKEND_BASE_URL}/build-step/${stepId}`, values, {
        withCredentials:true
      }); 
      // message.success('Step updated successfully');
      setEditStepId(null); // Exit edit mode
      // console.log(formattedValues);
        fetchStepsData(); // Refresh the data to show updated info
        setStepsData(freshStepData);
    } catch (error) {
      console.error('Failed to update step:', error);
      message.error('Failed to update step');
    }
  };
// HANDLE DONE ==============================================
const handleDone = async (stepId) => {
  try {
    // Send a request to the backend to mark the step as done
    await axios.post(`${BACKEND_BASE_URL}/build-step/${stepId}/done` ,{}, { withCredentials: true });
    // message.success('Step marked as done successfully');
    
    // Refresh the list of steps to show updated status
    fetchStepsData();
  } catch (error) {
    console.error('Failed to mark step as done:', error);
    message.error('Failed to mark step as done');
  }
};
// Check for Partial ======================================
 const handleDoneAndSave = async (stepId, values) => {
  let { quantityPass, quantityReject, quantity } = values;
  console.log('This is the currentStepValues:', currentStepValues);
  console.log('This is the values:', values);
  console.log('Quantity:', quantity);
  console.log('QuantityPass:', quantityPass);
  let quantityPassValue = parseInt(quantityPass);
  let quantityRejectValue = parseInt(quantityReject);
  let quantityValue = parseInt(quantity);
  // console.log('QuantityReject:', quantityReject);
  // let newQuantityPass = values.getFieldValue('quantityPass');
  //   let newQuantityReject = values.getFieldValue('quantityReject');
  //   let newQuantity = values.getFieldValue('quantity');
  if (quantityReject === undefined || quantityReject === null) {
    quantityReject = 0;
  };

  if (quantityPassValue + quantityRejectValue !== quantityValue) {
    setPartial(true);
    setShowModal(true);
    setCurrentStepValues(values);
    setCurrentStepId(stepId);
  } else {
    setPartial(false);
    proceedWithDoneAndSave(stepId, values, false);
}
};
// Done and Save ===================================
const proceedWithDoneAndSave = async (stepId, values, isPartial = partial, isStatus = !partial) => {
  const updatedValues = {
    ...values,
    partial: isPartial ,
    status: true,
  };
  try {
    await handleSave(stepId, updatedValues);
    await handleDone(stepId);
    console.log('This is the updatedValues:', updatedValues);
    message.success('Step updated and marked as done successfully');
  } catch (error) {
    console.error('Failed to update and mark step as done:', error);
    message.error('Failed to update and mark step as done');
  }
};

// ================================================
  useEffect(() => {
    const newSocket = io(`${BACKEND_BASE_URL}`);
    // console.log('This is the requierd Socket:', newSocket);
    setSocket(newSocket);
    fetchStepsData(); 
  
    newSocket.on('stepDataChange', async () => {
      await fetchStepsData(); // Call fetchStepsData with state update function
    });
  
    return () => {
      newSocket.off('stepDataChange', fetchStepsData);

    };
  }, []);
  const formFieldsByStepNumber = {
    1: [ 'ABRId', 'MEMSLotId', 'date', 'quantity', 'issuers'],
    2: [ 'lotNumber1', 'date', 'quantity', 'quantityPass','quantityReject', 'issuers'],
    3: [ 'lotNumber2', 'date', 'quantity', 'quantityPass', 'quantityReject','issuers'],
    4: [ 'lotNumber2', 'date', 'quantity', 'quantityPass', 'quantityReject','issuers'],
    5: [ 'lotNumber2', 'date', 'quantity', 'quantityPass', 'quantityReject','issuers'],
    6: [ 'lotNumber2', 'date', 'quantity', 'quantityPass', 'quantityReject','issuers'],
    7: [ 'lotNumber2', 'date', 'quantity', 'quantityPass', 'quantityReject','issuers'],
    8: [ 'lotNumber2', 'date', 'quantity', 'quantityPass', 'quantityReject','issuers'],
  };

  const getLabel = (field) => {
    switch (field) {
      case 'quantity':
        return 'Quantity Received';
      case 'quantityPass':
        return 'Quantity Pass';
      case 'quantityReject':
        return 'Quantity Reject';
      case 'lotNumber1':
        return 'Lot Number 1';
      case 'lotNumber2':
        return 'Lot Number 2';
      case 'ABRId':
        return 'ABR ID';
      case 'MEMSLotId':
        return 'MEMS Lot ID';
      
      default:
        return field.charAt(0).toUpperCase() + field.slice(1);
    }
  };
  const formatDate = (field, value) => {
    // Check if the field is 'date' and the value is not null or empty
    if (field === 'date' && value) {
      return new Date(value).toISOString().split('T')[0];
    
    } else if (field === 'date') {
      return '';
    }
    return value;
  };
  
  

  return (
    <div>
      {stepsData.map((build)=>(
        <Card 
        key={build.buildName}
        style={{ marginTop: '20px' }}
        title={<div style={{display: 'flex', justifyContent: 'space-between'}}><h3>{build.buildName}</h3><Button onClick={() => toggleExpandB(build.buildName)} style={{ cursor: 'pointer', marginTop: '10px' }}>
        {expandedBuilds.has(build.buildName) ? <UpOutlined /> : <DownOutlined />}
      </Button></div>}>
      {expandedBuilds.has(build.buildName) && (
        <>
      {build.steps.map((step) => (
        <Card
          key={step._id}
          style={{ marginTop: '20px' }}
          title={<div className="card-header" style={{  display: 'flex', justifyContent: 'space-between'}}>
        <div style={{ display: 'flex', alignItems: 'center'}}>
          {step.partial && <PartitionOutlined style={{ color: '#1d68e0', marginRight: '10px'}} />}
          {!step.status && <ExceptionOutlined style={{ color: 'red', marginRight: '10px'}} />}
          {/* <h2 style={{ fontSize: '17px', marginRight: '50px'}}>{step.buildName} </h2>  */}
          <p> {step.stepName} - Quantity Recieved: {step.quantity}</p>
        </div>
          <Button onClick={() => toggleExpand(step._id)} style={{ cursor: 'pointer', marginTop: '10px' }}>
            {expandedStepIds.has(step._id) ? <UpOutlined /> : <DownOutlined />}
          </Button>
        </div>}
        >
           {expandedStepIds.has(step._id) && (
            <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
          onClick={() => handleEditClick(step._id)}
          style={{ marginRight: '10px'}}
          disabled={!step.editable}
        >
          {editStepId === step._id ? 'Cancel Edit' : 'Edit'}
        </Button>
        </div>
        <Modal
  title="Partial Update Confirmation"
  open={showModal}
  onOk={() => {
    proceedWithDoneAndSave(currentStepId, currentStepValues, true);
    setShowModal(false);
  }}
  onCancel={() => setShowModal(false)}
>
  <p>The sum of Quantity Passed and Quantity Rejected is less than the total Quantity received. Do you want to proceed with a partial update?</p>
</Modal>
{/* If edit mode is active, show form with inputs */}
{editStepId === step._id ? (
  <div >
  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
  <Button type="primary" form={`form-${step._id}`} style={{ margin: '10px', padding: '5px 10px', marginTop: '10px', marginBottom: '40px' }} key="submit" htmlType="submit">
  Save Changes
</Button>
</div>
          <Form
            layout="inline"
            // form={form}
            initialValues={{
              ...step,
              date: step.date ? moment(step.date) : null
            }}
            onFinish={(values) => handleDoneAndSave(step._id, values, false)}
            id={`form-${step._id}`}
            onChange={handleInputChange}
            value={currentStepValues}
          >
            
            {(formFieldsByStepNumber[step.stepNumber] || []).map((field) => (
              <Form.Item
                key={field}
                name={field}
                // label={field.charAt(0).toUpperCase() + field.slice(1)}
                label={getLabel(field)}
                style={{ margin: '10px', padding: '5px' }}
              >
                {field === 'date' ? (
                  <DatePicker format="YYYY-MM-DD" />
                ) : (
                  <Input disabled={editStepId !== step._id } />
                )}
              </Form.Item>
            ))}
            {editStepId === step._id && (
              <Row justify="end">
                {/* <Col>
                  <Button type="primary" form={`form-${step._id}`} style={{ margin: '10px', padding: '5px 10px', marginTop: '15px' }} key="submit" htmlType="submit">
                    Save Changes
                  </Button>
                </Col> */}
              </Row>
            )}
          </Form>
          </div>
           ) : (
            // If not in edit mode, display current values as plain text
            <div>
              {(formFieldsByStepNumber[step.stepNumber] || []).map((field) => (
                <p key={field}>
                  <strong>{getLabel(field)}:</strong> {formatDate(field, step[field])}
                </p>
              ))}

            </div>
             )}
        </>
         )}
         </Card>
       ))}
       </>
      )}
     </Card>
   ))}
 </div>
  );
};

export default DataTable;
