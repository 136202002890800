import React, { useEffect, useState } from 'react';
import { Card, Button } from 'antd';
import AddPermissionForm from './AddPermissionForm';
import axios from 'axios';
import { BACKEND_BASE_URL } from '../../config/api';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
// ==================================================================================
// PermissionCard component
// READ ME:
//
// This component is used to display the permissions for each step. It fetches the step details from the backend and renders an AddPermissionForm component for each step. The AddPermissionForm component is used to manage permissions for a specific step.
// ==================================================================================

const PermissionCard = () => {
  const [steps, setSteps] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const fetchStepDetails = async () => {
      try {
        const response = await axios.get(`${BACKEND_BASE_URL}/admin/details`, { withCredentials: true });
        setSteps(response.data);
      } catch (error) {
        console.error('Failed to fetch step details:', error);
      }
    };

    fetchStepDetails();
  }, []);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded); 
  };

  return (
    <Card title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '20px', fontWeight: 'bold' }}>
      Permissions
      <Button onClick={toggleExpand} style={{ cursor: 'pointer' }}>
  {isExpanded ? <UpOutlined /> : <DownOutlined />}
</Button>

    </div>} style={{border: '3px solid #e8e8e8'}}>
      {isExpanded && steps.map(({ stepNumber, stepName }) => (
        <AddPermissionForm key={stepNumber} stepNumber={stepNumber} stepName={stepName} />
      ))}
    </Card>
  );
};

export default PermissionCard;
