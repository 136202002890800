import { Button, Form, Input, DatePicker, Space } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { BACKEND_BASE_URL } from '../../config/api';
// ==================================================================================
// AddStepForm component
// READ ME:
//
// This component is used to add a new step. It contains a form with input fields for the buildName, ABRId, MEMSLotId, quantity, and date which we need for the first step and we are sending the stepNumber as 1 from here to backend based on the requirement of this project. The form submission logic is handled by the handleFormSubmit function, which sends a POST request to the backend API to add a new step. The form also includes a cancel button to close the modal and a submit button to trigger the form submission.
// ==================================================================================

const AddStepForm = ({ onCancel, onSubmitSuccess }) => { // Assume currentUser contains the current user's info
    const [form] = Form.useForm();

  
    const handleFormSubmit = async (values) => {
      // Your form submission logic, including sending the permissions and issuer
      if (!values.date) {
        values.date = moment().format('YYYY-MM-DD');
      }
      const stepNumber = 1;
      try {
        const response = await axios.post(`${BACKEND_BASE_URL}/admin/add`, {
          ...values, 
          stepNumber,
          dateIssued: values.date,
        }, { withCredentials: true});
        console.log(response.data);
        if (onSubmitSuccess) {
          onSubmitSuccess(); // Invoke the callback function to close the modal
        }
      } catch (error) {
        console.error('Error adding new step:', error);
      }
    };
  return (
    <Form form={form} onFinish={handleFormSubmit} layout="vertical" autoComplete="off">
          <Form.Item name="buildName" label="Build Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="ABRId" label="ABR ID">
            <Input />
          </Form.Item>
          <Form.Item name="MEMSLotId" label="MEMS Lot ID">
            <Input />
          </Form.Item>
          <Form.Item name="quantity" label="Quantity" rules={[{ required: true }]} >
            <Input type="number" />
          </Form.Item>
          <Form.Item name="issuers" label="Issuer">
            <Input />
          </Form.Item>
          <Form.Item name="date" label="Date">
            <DatePicker defaultValue={moment()} format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item>
          <Button type="primary" htmlType="submit" block style={{ marginTop: '10px' }} >
              Submit
            </Button>
          </Form.Item>
        </Form>
  );
};
export default AddStepForm;