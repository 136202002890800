import React, { useState, useEffect } from 'react';
import { Button, Card } from 'antd';
import logo from '../exo_logo.jpg';
import {BACKEND_BASE_URL} from '../config/api.js';
import axios from 'axios';
// ================================================


const Login = () => {

  const [buttonDisabled, setButtonDisabled] = useState(true);


  const handleLogin = (e) => {
    e.preventDefault();
    window.location.href = `${BACKEND_BASE_URL}/auth/signin`;

  };


  useEffect(() => {
       axios.get(`${BACKEND_BASE_URL}/auth/profile`, { withCredentials: true }).then (res => {
        if (res.data.userType === 'Admin'){
        window.location.href = `/admin`
        } else if (res.data.userType === 'User'){
        window.location.href = `/user`
        }
        
      }).catch(err => {
        // console.error('Error fetching user profile:', err);
        setButtonDisabled(false);
      });
    }
  , []);
  

  return (
    <div style={{ maxWidth: 600, margin: 'auto', paddingTop: 100 }}>
      <Card style={{ textAlign: 'center' }}>
      <img src={logo} alt="Logo" style={{ height: '80px'}} />
      <h1>EXO Build Tracking Platform</h1>
      <p>Login through Okta pltaform</p>
      <Button type="primary" htmlType='submit'
      disabled={buttonDisabled}
      onClick={handleLogin} style={{ fontSize: '16px', height: '50px', padding: '0 30px', margin:'20px' }}>Login</Button>
      </Card>
    </div>
  );
};

export default Login;
