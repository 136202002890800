let BACKEND_BASE_URL;
if(process.env.NODE_ENV === 'development') {
BACKEND_BASE_URL = "http://localhost:9000";
}else if(process.env.NODE_ENV === 'production') {
BACKEND_BASE_URL = "https://api-exo-build-tracking-platform.medo.ai";
}

let BASE_URL;
if(process.env.NODE_ENV === 'development') {
BASE_URL = "http://localhost:3000";
} else if(process.env.NODE_ENV === 'production') {
BASE_URL = "https://exo-build-tracking-platform.medo.ai";
}

export {BACKEND_BASE_URL, BASE_URL};
