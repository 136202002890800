import React, { useState } from 'react';
import { Button, Modal, List } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { BACKEND_BASE_URL } from '../../config/api';
// ==================================================================================
// ViewAllStepsButton component
// READ ME:
//
// This component is used to view all steps. It contains a button that opens a modal with a list of all steps.
// ==================================================================================
const ViewAllStepsButton = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allSteps, setAllSteps] = useState([]);

  const showModal = async () => {
    try {
      const response = await axios.get(`${BACKEND_BASE_URL}/admin/details`, { withCredentials: true }); 
      setAllSteps(response.data);
      setIsModalVisible(true);
    } catch (error) {
      console.error('Error fetching all steps:', error);
    }
  };

  return (
    <>
      <Button icon={<InfoCircleOutlined />} onClick={showModal} style={{ float: 'right', margin: '10px' }}>
      </Button>
      <Modal
        title="All Steps"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <List
          bordered
          dataSource={allSteps}
          renderItem={item => (
            <List.Item>
              <div>
              {`Step ${item.stepNumber}: ${item.stepName}`}
               <div style={{ margin: '6px' }}>{item.description}</div>
              </div>
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default ViewAllStepsButton;
